// extracted by mini-css-extract-plugin
export var blockOne = "MyData-module--blockOne--VQ2R-";
export var blockTwo = "MyData-module--blockTwo--cHgaU";
export var blocks = "MyData-module--blocks--s0QEa";
export var box = "MyData-module--box--xi-BQ";
export var breadcrumbs = "MyData-module--breadcrumbs--ub1C6";
export var container = "MyData-module--container--bUTXH";
export var current = "MyData-module--current--xwdi7";
export var header = "MyData-module--header--SeIWE";
export var heading = "MyData-module--heading--83ZJZ";
export var icon = "MyData-module--icon--kjn-l";
export var main = "MyData-module--main--y+gWl";
export var myDataPage = "MyData-module--myDataPage--I+GHp";
export var previous = "MyData-module--previous--6lraD";