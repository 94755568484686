import React from "react";

import { ChangeEvent } from "react";
import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";
import { MouseEvent } from "react";

import clsx from "clsx";

import { createRef } from "react";
import { setProfileComplete } from "@js/actionCreators/statusActionCreators";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector} from "react-redux";
import { useState } from "react";

import Button from "@js/component/Button";
import CheckIcon from "@js/component/icon/CheckIcon";
import CloudUploadIcon from "@js/component/icon/CloudUploadIcon";
import EyeIcon from "@js/component/icon/EyeIcon";
import Heading from "@js/component/Heading";
import Input from "@js/component/Input";
import PlusCircleIcon from "@js/component/icon/PlusCircleIcon";
import Textarea from "@js/component/Textarea";

import StoreState from "@js/store/StoreState";

import * as classNames from "@css/component/MyDataView.module.scss";

/**
 * @type MyDataViewProps
 */
export type MyDataViewProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children">;

/**
 * @const MyDataView
 */
const MyDataView = (props: MyDataViewProps) => {
    const {
        className,
        ...restProps
    } = props;

    const dispatch = useDispatch();

    const formRef = createRef<HTMLFormElement>();

    const logoInputRef = createRef<HTMLInputElement>();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [address, setAddress] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [city, setCity] = useState("");
    const [promo, setPromo] = useState("");
    const [strongPoints, setStrongPoints] = useState(["", "", "", "", ""]);
    const [contactPersons, setContactPersons] = useState([{
        certificationNumber: "",
        contactPerson: "",
        startingDate: ""
    }]);
    const [logoFile, setLogoFile] = useState<File | null>(null);
    const [logoPreview, setLogoPreview] = useState("");
    const [logoName, setLogoName] = useState("");
    const [certificateNumber, setCertificateNumber] = useState("");
    const [certificateCompany, setCertificateCompany] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const [isFetching, setIsFetching] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [isFailed, setIsFailed] = useState(false);

    const [errors, setErrors] = useState<{ [key: string]: string[]; }>({});

    const onSaveButtonClick = async (event: MouseEvent) => {
        event.preventDefault();

        if (formRef.current?.reportValidity()) {
            setIsSaving(true);

            const response = await fetch(`${ process.env.GATSBY_API_ADVISOR_URL }/myData?token=${ token }`, {
                body: JSON.stringify({
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    companyName: companyName,
                    address: address,
                    zipCode: zipCode,
                    city: city,
                    companyImage: {
                        originalName: logoName,
                        file: logoPreview
                    },
                    companyPromo: promo,
                    strongPoints: strongPoints.map((strongPoint) => {
                        return { message: strongPoint };
                    }),
                    contactPersons: contactPersons.map((contactPerson) => {
                        return {
                            name: contactPerson.contactPerson,
                            certificationNumber: contactPerson.certificationNumber,
                            startingDate: contactPerson.startingDate
                        };
                    }),
                    certificateNumber: certificateNumber,
                    certificateCompany: certificateCompany,
                    password1: password,
                    password2: passwordRepeat,
                    phoneNumber: phoneNumber
                }),
                method: "PUT"
            });

            const data = await response.json();

            if (data.status == "failed") {
                setIsFailed(true);
                setErrors(data.errors);

                setTimeout(() => {
                    setIsFailed(false);
                }, 5000);
            }
            else {
                dispatch(setProfileComplete(data.profileComplete));

                setIsSaved(true);
                setErrors({});

                setTimeout(() => {
                    setIsSaved(false);
                }, 10000);
            }

            setIsSaving(false);
        }
    };

    const onLogoChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        const fileReader = new FileReader();

        if (event.target.files) {
            const file = event.target.files[0];

            fileReader.addEventListener("loadend", () => {
                setLogoFile(file);
                setLogoPreview(fileReader.result as string);
                setLogoName(file.name);
            });

            fileReader.readAsDataURL(file);
        }
    };

    const onRemoveLogo = () => {
        setLogoFile(null);
        setLogoPreview("");
        setLogoName("");

        if (logoInputRef.current) {
            logoInputRef.current.value = "";
        }
    };

    const onAddContactPersonRow = () => {
        const copyOfContactPersons = [ ...contactPersons ];

        copyOfContactPersons.push({
            certificationNumber: "",
            contactPerson: "",
            startingDate: ""
        });

        setContactPersons(copyOfContactPersons);
    };

    const onRemoveContactPersonRow = (index: number) => () => {
        const copyOfContactPersons = [ ...contactPersons ];

        copyOfContactPersons.splice(index, 1);

        setContactPersons(copyOfContactPersons);
    };

    const token = useSelector((storeState: StoreState) => storeState.security.token);

    const getMyData = async () => {
        setIsFetching(true);

        const response = await fetch(`${ process.env.GATSBY_API_ADVISOR_URL }/myData?token=${ token }`, {
            method: "GET"
        });

        const data = await response.json();

        setIsFetching(false);

        setFirstName(data.firstName || "");
        setLastName(data.lastName || "");
        setCompanyName(data.companyName || "");
        setPhoneNumber(data.phoneNumber || "");
        setAddress(data.address || "");
        setZipCode(data.zipCode || "");
        setCity(data.city || "");
        setPromo(data.companyPromo || "");

        // setLogo()
        setLogoPreview(data.companyImage.file);
        setLogoName(data.companyImage.originalName);

        const defaultStrongPoints = data.strongPoints.map((strongPoint) => {
            return strongPoint.message;
        });

        setStrongPoints(defaultStrongPoints);
        setCertificateNumber(data.certificateNumber);
        setCertificateCompany(data.certificateCompany);

        setContactPersons(data.contactPersons.map((contactPerson ) => {
            return {
                certificationNumber: contactPerson.certificationNumber,
                contactPerson: contactPerson.name,
                startingDate: new Date(contactPerson.startingDate).toISOString().split('T')[0]
            };
        }));

        setEmail(data.email);
    };

    useEffect(() => {
        getMyData();
    }, []);

    return (
        <div { ...restProps } className={ clsx(classNames.myDataView, className) }>
            { isSaved &&
                <div className={ classNames.saved }>
                    {`Je gegevens zijn succesvol opgeslagen! Je kunt nu beginnen met het invullen van "Mijn producten".`}
                </div>
            }
            { isFailed && <div className={ classNames.failed }>Er bevinden zich fouten in het formulier.</div> }
            <div className={ classNames.container }>
                <Heading className={ classNames.heading } element="h1" variant="medium">Mijn gegevens</Heading>
                <p className={ classNames.paragraph }>
                    Op deze pagina van het dashboard van jouw bedrijf wijzig je de contactgegevens waarmee we jouw bedrijf kunnen bereiken
                    en opdrachtbevestigingen mee genereren. Verder geef je hier invulling aan jouw openbare bedrijfsprofiel op Woninglabel.nl.
                    Wat zijn specifieke bedrijfskenmerken die jouw potentiële klant moeten weten om jouw bedrijf uit de vergelijking
                    van de beschikbare energielabel-adviseurs te selecteren?
                    Registreer hier ook jezelf en/of je collega(’s) als de energielabel-adviseur(s) voor de op te stellen energielabels.
                    Laat ons tot slot weten welk bedrijf de opgestelde energielabels bij de overheid registreert
                    en onder welk certificaatnummer dit gebeurt.
                    Wanneer je alle gegevens hebt ingevuld en deze opslaat wordt de pagina ‘Mijn producten’ opengesteld,
                    welke je linksboven terugvindt in het snelmenu.
                </p>

                <form ref={ formRef }>
                    <Heading className={ classNames.headingSub } element="h2" variant="small">Mijn contactgegevens</Heading>

                    <div className={ classNames.name }>
                        <div className={ classNames.firstName }>
                            <label className={ classNames.label } htmlFor="first_name">Voornaam *</label>
                            <Input
                                className={ clsx(classNames.input, {
                                    [classNames.error]: errors.firstName
                                }) }
                                disabled={ isFetching }
                                id="first_name"
                                name="first_name"
                                onChange={ (event) => setFirstName(event.currentTarget.value) }
                                // required
                                value={ firstName }
                            />
                        </div>
                        <div className={ classNames.lastName }>
                            <label className={ classNames.label } htmlFor="last_name">Achternaam *</label>
                            <Input
                                className={ clsx(classNames.input, {
                                    [classNames.error]: errors.lastName
                                }) }
                                disabled={ isFetching }
                                id="last_name"
                                name="last_name"
                                onChange={ (event) => setLastName(event.currentTarget.value) }
                                // required
                                value={ lastName }
                            />
                        </div>
                    </div>

                    <ul className={ classNames.errors }>
                        { errors.firstName &&
                          errors.firstName.map((error, index) => (
                            <li className={ classNames.error } key={ index }>{ error }</li>
                        )) }
                        { errors.lastName &&
                          errors.lastName.map((error, index) => (
                            <li className={ classNames.error } key={ index }>{ error }</li>
                        )) }
                    </ul>

                    <div className={ classNames.labelWrapper }>
                        <label className={ classNames.label } htmlFor="company_name">Bedrijfsnaam *</label>
                        <div className={ classNames.visibility }>
                            <EyeIcon className={ classNames.icon } />
                            <span>Zichtbaar op profielpagina</span>
                        </div>
                    </div>

                    <Input
                        className={ clsx(classNames.input, {
                            [classNames.error]: errors.companyName
                        }) }
                        disabled={ isFetching }
                        id="company_name"
                        name="company_name"
                        onChange={ (event) => setCompanyName(event.currentTarget.value) }
                        // required
                        value={ companyName }
                    />

                    <ul className={ classNames.errors }>
                        { errors.companyName &&
                          errors.companyName.map((error, index) => (
                            <li className={ classNames.error } key={ index }>{ error }</li>
                        )) }
                    </ul>

                    <label className={ classNames.label } htmlFor="phone_number">Telefoonnummer *</label>
                    <Input
                        className={ clsx(classNames.input, {
                            [classNames.error]: errors.phoneNumber
                        }) }
                        disabled={ isFetching }
                        id="phone_number"
                        name="phone_number"
                        onChange={ (event) => setPhoneNumber(event.currentTarget.value) }
                        // required
                        value={ phoneNumber }
                    />

                    <ul className={ classNames.errors }>
                        { errors.phoneNumber &&
                          errors.phoneNumber.map((error, index) => (
                            <li className={ classNames.error } key={ index }>{ error }</li>
                        )) }
                    </ul>

                    <label className={ classNames.label } htmlFor="address">Adres *</label>
                    <Input
                        className={ clsx(classNames.input, {
                            [classNames.error]: errors.address
                        }) }
                        disabled={ isFetching }
                        id="address"
                        name="address"
                        onChange={ (event) => setAddress(event.currentTarget.value) }
                        // required
                        value={ address }
                    />

                    <ul className={ classNames.errors }>
                        { errors.address &&
                          errors.address.map((error, index) => (
                            <li className={ classNames.error } key={ index }>{ error }</li>
                        )) }
                    </ul>

                    <label className={ classNames.label } htmlFor="zip_code">Postcode *</label>
                    <Input
                        className={ clsx(classNames.input, {
                            [classNames.error]: errors.zipCode
                        }) }
                        disabled={ isFetching }
                        id="zip_code"
                        name="zip_code"
                        onChange={ (event) => setZipCode(event.currentTarget.value) }
                        // required
                        value={ zipCode }
                    />

                    <ul className={ classNames.errors }>
                        { errors.zipCode &&
                          errors.zipCode.map((error, index) => (
                            <li className={ classNames.error } key={ index }>{ error }</li>
                        )) }
                    </ul>

                    <label className={ classNames.label } htmlFor="city">Vestigingsplaats *</label>
                    <Input
                        className={ clsx(classNames.input, {
                            [classNames.error]: errors.city
                        }) }
                        disabled={ isFetching }
                        id="city"
                        name="city"
                        onChange={ (event) => setCity(event.currentTarget.value) }
                        // required
                        value={ city }
                    />

                    <ul className={ classNames.errors }>
                        { errors.city &&
                          errors.city.map((error, index) => (
                            <li className={ classNames.error } key={ index }>{ error }</li>
                        )) }
                    </ul>

                    <Heading className={ classNames.headingSub } element="h2" variant="small">Mijn profiel</Heading>

                    <div className={ classNames.labelWrapper }>
                        <label className={ classNames.label }>Logo (JPG of PNG, max. 1 MB) *</label>
                        <div className={ classNames.visibility }>
                            <EyeIcon className={ classNames.icon } />
                            <span>Zichtbaar op profielpagina</span>
                        </div>
                    </div>

                    <div className={ classNames.logo }>
                        { logoPreview &&
                            <div className={ classNames.preview }>
                                <img alt={ logoName } className={ classNames.image } src={ logoPreview } />
                                <button className={ classNames.remove } onClick={ onRemoveLogo } type="button">
                                    Verwijder logo
                                </button>
                            </div>
                        }
                        <div className={ classNames.upload }>
                            <CloudUploadIcon className={ classNames.icon } />
                            <strong className={ classNames.uploadText }>Upload hier je logo</strong>
                            <label className={ classNames.browse }>
                                <input
                                    accept="image/png, image/jpeg"
                                    className={ classNames.input }
                                    disabled={ isFetching }
                                    name="logo"
                                    onChange={ onLogoChange }
                                    ref={ logoInputRef }
                                    type="file"
                                />
                                <span className={ classNames.browseText }>Blader door je bestanden</span>
                            </label>
                        </div>
                    </div>

                    <ul className={ classNames.errors }>
                        { errors.companyImage &&
                          errors.companyImage.map((error, index) => (
                            <li className={ classNames.error } key={ index }>{ error }</li>
                        )) }
                    </ul>

                    <div className={ classNames.labelWrapper }>
                        <label className={ classNames.label } htmlFor="promo">
                            Promoot hier je bedrijf (max. 100 woorden)
                        </label>
                        <div className={ classNames.visibility }>
                            <EyeIcon className={ classNames.icon } />
                            <span>Zichtbaar op profielpagina</span>
                        </div>
                    </div>

                    <div className={ classNames.wordCountWrapper }>
                        <Textarea
                            className={ clsx(classNames.textarea, {
                                [classNames.error]: errors.companyPromo
                            }) }
                            disabled={ isFetching }
                            id="promo"
                            name="promo"
                            onChange={ (event) => {
                                if (event.currentTarget.value.split(/\s+/).filter(Boolean).length <= 100) {
                                    setPromo(event.currentTarget.value);
                                }
                            } }
                            placeholder="Begin hier met typen"
                            // required
                            value={ promo }
                        />
                        <div className={ classNames.wordCount }>{ promo.split(/\s+/).filter(Boolean).length } woord(en)</div>
                    </div>

                    <ul className={ classNames.errors }>
                        { errors.companyPromo &&
                          errors.companyPromo.map((error, index) => (
                            <li className={ classNames.error } key={ index }>{ error }</li>
                        )) }
                    </ul>

                    <fieldset className={ classNames.fieldset }>
                        <div className={ classNames.labelWrapper }>
                            <legend className={ classNames.legend }>Wat zijn jullie sterke punten? (max. 5)</legend>
                            <div className={ classNames.visibility }>
                                <EyeIcon className={ classNames.icon } />
                                <span>Zichtbaar op profielpagina</span>
                            </div>
                        </div>

                        <div className={ classNames.strongPoints }>
                            <ul className={ classNames.list }>
                                { [0, 1, 2, 3, 4].map((strongPoint, index) => (
                                    <li className={ classNames.listItem } key={ index }>
                                        <div className={ classNames.iconCircle }>
                                            <CheckIcon className={ classNames.icon } />
                                        </div>
                                        <label className={ classNames.labelHidden } htmlFor={ `strong_point_${ index }` }>
                                            Sterk punt { index }
                                        </label>
                                        <Input
                                            className={ clsx(classNames.input, {
                                                [classNames.error]: strongPoints[index] && strongPoints[index].length > 50
                                            }) }
                                            disabled={ isFetching }
                                            id={ `strong_point_${ index }` }
                                            maxLength={ 50 }
                                            name={ `strong_point_${ index }` }
                                            onChange={ (event) => {
                                                const copyOfStrongPoints = [ ...strongPoints ];

                                                copyOfStrongPoints[index] = event.currentTarget.value;

                                                setStrongPoints(copyOfStrongPoints);
                                            } }
                                            placeholder="Bijvoorbeeld: 20 jaar ervaring"
                                            value={ strongPoints[index] ?? '' }
                                        />
                                    </li>
                                )) }
                            </ul>
                        </div>
                        <ul className={ classNames.errors }>
                            { errors.strongPoints && errors.strongPoints[0] &&
                             (errors.strongPoints[0] as unknown as { message: string[]; }).message.map((error, index) => (
                                <li className={ classNames.error } key={ index }>{ error }</li>
                            )) }
                        </ul>
                    </fieldset>

                    <div className={ classNames.contactPersons }>
                        { contactPersons.length == 0 &&
                            <div className={ classNames.noContactPersons }>Er zijn nog geen energielabel-adviseurs toegevoegd.</div>
                        }
                        <ul className={ classNames.list }>
                            { contactPersons.map((contactPerson, index) => (
                                <li className={ classNames.listItem } key={ index }>
                                    <div className={ classNames.contactPerson }>
                                        <label
                                            className={ classNames.label }
                                            htmlFor={ `contact_person_${ index }`}
                                        >
                                            Energielabel-adviseur *
                                        </label>
                                        <Input
                                            disabled={ isFetching }
                                            id={ `contact_person_${ index }` }
                                            name={ `contact_person_${ index }` }
                                            onChange={ (event) => {
                                                const copyOfContactPersons = [ ...contactPersons ];

                                                copyOfContactPersons[index].contactPerson = event.currentTarget.value;

                                                setContactPersons(copyOfContactPersons);
                                            } }
                                            placeholder="Naam"
                                            // required
                                            value={ contactPerson.contactPerson }
                                        />
                                    </div>
                                    <div className={ classNames.certificationNumber }>
                                        <label
                                            className={ classNames.label }
                                            htmlFor={ `certification_number_${ index }` }
                                        >
                                            Qbis examennummer *
                                        </label>
                                        <Input
                                            disabled={ isFetching }
                                            id={ `certification_number_${ index }` }
                                            name={ `certification_number_${ index }` }
                                            onChange={ (event) => {
                                                const copyOfContactPersons = [ ...contactPersons ];

                                                copyOfContactPersons[index].certificationNumber = event.currentTarget.value;

                                                setContactPersons(copyOfContactPersons);
                                            } }
                                            placeholder="Nummer"
                                            // required
                                            value={ contactPerson.certificationNumber }
                                        />
                                    </div>
                                    <div className={ classNames.startingDate }>
                                        <label
                                            className={ classNames.label }
                                            htmlFor={ `starting_date_${ index }` }
                                        >
                                            Ingangsdatum *
                                        </label>
                                        <Input
                                            disabled={ isFetching }
                                            id={ `starting_date_${ index }` }
                                            name={ `starting_date_${ index }` }
                                            onChange={ (event) => {
                                                const copyOfContactPersons = [ ...contactPersons ];

                                                copyOfContactPersons[index].startingDate = event.currentTarget.value;

                                                setContactPersons(copyOfContactPersons);
                                            } }
                                            // required
                                            type="date"
                                            value={ contactPerson.startingDate }
                                        />
                                    </div>
                                    <div className={ classNames.removeWrapper }>
                                        <button
                                            className={ clsx(classNames.remove, {
                                                [classNames.disabled]: false // contactPersons.length == 1
                                            }) }
                                            onClick={ onRemoveContactPersonRow(index) }
                                            type="button"
                                        >
                                            Verwijder
                                        </button>
                                    </div>
                                </li>
                            )) }
                        </ul>
                    </div>

                    <ul className={ clsx(classNames.errors, classNames.advisors) }>
                        { errors.contactPersons &&
                          errors.contactPersons.map((error, index) => (
                            <li className={ classNames.error } key={ index }>{ error }</li>
                        )) }
                    </ul>

                    <div className={ classNames.addRowButtonWrapper }>
                        <button className={ classNames.addRowButton } onClick={ onAddContactPersonRow } type="button">
                            <PlusCircleIcon className={ classNames.icon } /> Voeg energielabel-adviseur toe
                        </button>
                    </div>

                    <div className={ classNames.details }>
                        <div className={ classNames.certificationNumber }>
                            <label className={ classNames.label } htmlFor="certificate_number">
                                Nummer certificaathouder 9500-1 *
                            </label>
                            <Input
                                className={ clsx(classNames.input, {
                                    [classNames.error]: errors.certificateNumber
                                }) }
                                disabled={ isFetching }
                                id="certificate_number"
                                name="certificate_number"
                                onChange={ (event) => setCertificateNumber(event.currentTarget.value) }
                                placeholder="Nummer"
                                // required
                                value={ certificateNumber }
                            />
                        </div>
                        <div className={ classNames.companyName }>
                            <label className={ classNames.label } htmlFor="certificate_company">
                                Bedrijfsnaam certificaathouder 9500-1 *
                            </label>
                            <Input
                                className={ clsx(classNames.input, {
                                    [classNames.error]: errors.certificateCompany
                                }) }
                                disabled={ isFetching }
                                id="certificate_company"
                                name="certificate_company"
                                onChange={ (event) => setCertificateCompany(event.currentTarget.value) }
                                placeholder="Naam"
                                // required
                                value={ certificateCompany }
                            />
                        </div>
                    </div>

                    <ul className={ classNames.errors }>
                        { errors.certificateNumber &&
                          errors.certificateNumber.map((error, index) => (
                            <li className={ classNames.error } key={ index }>{ error }</li>
                        )) }
                        { errors.certificateCompany &&
                          errors.certificateCompany.map((error, index) => (
                            <li className={ classNames.error } key={ index }>{ error }</li>
                        )) }
                    </ul>

                    <Heading className={ classNames.headingSub } element="h2" variant="small">Accountgegevens</Heading>

                    <label className={ classNames.label } htmlFor="email">E-mailadres *</label>
                    <Input
                        className={ clsx(classNames.input, {
                            [classNames.error]: errors.email
                        }) }
                        disabled={ isFetching }
                        id="email"
                        name="email"
                        onChange={ (event) => setEmail(event.currentTarget.value) }
                        // required
                        value={ email }
                    />

                    <ul className={ classNames.errors }>
                        { errors.email &&
                          errors.email.map((error, index) => (
                            <li className={ classNames.error } key={ index }>{ error }</li>
                        )) }
                    </ul>

                    <label className={ classNames.label } htmlFor="password">Wachtwoord</label>
                    <Input
                        autoComplete="new-password"
                        className={ clsx(classNames.input, {
                            [classNames.error]: errors.password1
                        }) }
                        disabled={ isFetching }
                        id="password"
                        name="new_password"
                        onChange={ (event) => setPassword(event.currentTarget.value) }
                        type="password"
                        value={ password }
                    />

                    <ul className={ classNames.errors }>
                        { errors.password1 &&
                          errors.password1.map((error, index) => (
                            <li className={ classNames.error } key={ index }>{ error }</li>
                        )) }
                    </ul>

                    <label className={ classNames.label } htmlFor="password_repeat">Herhaal wachtwoord</label>
                    <Input
                        autoComplete="new-password"
                        className={ clsx(classNames.input, {
                            [classNames.error]: errors.password2
                        }) }
                        disabled={ isFetching }
                        id="password_repeat"
                        name="new_password_repeat"
                        onChange={ (event) => setPasswordRepeat(event.currentTarget.value) }
                        type="password"
                        value={ passwordRepeat }
                    />

                    <ul className={ classNames.errors }>
                        { errors.password2 &&
                          errors.password2.map((error, index) => (
                            <li className={ classNames.error } key={ index }>{ error }</li>
                        )) }
                    </ul>

                    <p className={ classNames.paragraph }>
                        * verplichte gegevens om profiel zichtbaar te maken
                    </p>
                    <div className={ classNames.buttonWrapper }>
                        <Button className={ classNames.button } disabled={ isSaving } onClick={ onSaveButtonClick }>
                            { !(isSaving) && "Opslaan" }
                            { isSaving && "Bezig met opslaan..." }
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default MyDataView;
